import * as React from 'react'
import { graphql } from 'gatsby'
import { Layout, PageTitle } from '../../components/layout'
import { RichText } from '../../components/content'

const Music = ({ data }) => {
    const music = data.music

    return (
        <Layout seo={music?.seo}>
            <PageTitle
                title={music?.title}
                featuredImage={music?.cover}
            />
            <RichText blocks={music.description._rawContent} />
        </Layout>
    )
}

export default Music

export const query = graphql`
    query MusivQuery($id: String!) {
        music: sanityMusic(id: { eq: $id }) {
            title
            tracks
            slug {
                current
            }
            cover {
                asset {
                    gatsbyImageData
                }
            }
            releaseDate(formatString: "MMMM do YYYY")
            description {
                _rawContent
            }
        }
    }
`